.scoring{
font-size: large;
font-weight: 700;
background-color: #0bb6ad;
border-radius: 4px;
padding: 4px;
margin-top: 5px;
margin-bottom: 5px;
text-align: center;
}
.scoring-A{
     color: yellow;

}
.scoring-B{
    color: orange;
    
}
.scoring-C{
    color: red;
}