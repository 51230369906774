html{
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  background: #eef5f9;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
p{
  margin: 0;
}